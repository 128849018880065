import { Box } from '@mui/material';
import { GridColDef, GridColumnGroupingModel, GridValueGetterParams } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { StyleObj } from '../../../../@types';
import { PaginatedData, WalletCredit } from '../../../../@types/api';
import { QUERY_KEYS } from '../../../../constants';
import { createColumn } from '../../../../utils';
import { getData } from '../../../../utils/api';
import DateRangeSelect, { DayjsTimestamp } from '../../../molecules/DateRangeSelect';
import TableTemplate from '../../../templates/TableTemplate';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
  table: {
    mt: 5,
    border: 'none',
    '--unstable_DataGrid-radius': '0',
    '& .MuiDataGrid-withBorderColor': {
      border: 'none',
    },
    '& .MuiDataGrid-columnHeader': {
      border: '1px solid rgba(0, 83, 55, 0.20)',
      backgroundColor: 'background.lightGreen',
      '&:first-of-type': {
        borderRadius: 0,
      },
      '&:not(:first-of-type)': {
        borderLeft: 'none',
      },
      '&:last-of-type': {
        borderRadius: 0,
        width: '100% !important',
        maxWidth: 'unset !important',
      },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      width: '100%',
      '> div': {
        width: '100%',
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      borderRadius: 0,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      color: 'grey.700',
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: 'background.light',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .customGroupHeader': {
      backgroundColor: 'primary.main',
      '&:first-of-type': {
        borderTopLeftRadius: 8,
      },
      '&:last-of-type': {
        borderTopRightRadius: 8,
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
        color: 'white',
      },
    },
  },
};

const DATA_TYPES = {
  amountSum: 'Sum',
  countNum: 'Number',
};

const formatNumberValue = (value: number, dataType: keyof typeof DATA_TYPES) => {
  return dataType === 'amountSum' ? value.toFixed(2) : value;
};

const createNumberColumn = (field: string, headerName: string, rowAccessor: (row: WalletCredit) => number) => {
  return createColumn(field, headerName, {
    sortable: false,
    valueGetter: (params: GridValueGetterParams<WalletCredit>) =>
      formatNumberValue(rowAccessor(params.row), params.row.dataType),
  });
};

const columns: GridColDef[] = [
  createColumn('dataType', '', {
    sortable: false,
    valueGetter: (params: GridValueGetterParams<WalletCredit>) => DATA_TYPES[params.row.dataType],
  }),
  createNumberColumn('deposit', 'Deposit', (row) => row.in.deposit),
  createNumberColumn('bonus', 'Bonus', (row) => row.in.bonus),
  createNumberColumn('otherIn', 'Other', (row) => row.in.other),
  createNumberColumn('totalIn', 'Total', (row) => row.in.total),
  createNumberColumn('withdrawal', 'Withdrawal', (row) => row.out.withdrawal),
  createNumberColumn('otherOut', 'Other', (row) => row.out.other),
  createNumberColumn('totalOut', 'Total', (row) => row.out.total),
];

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'Total',
    description: 'Total',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'dataType' }],
  },
  {
    groupId: 'In',
    description: 'In',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'deposit' }, { field: 'bonus' }, { field: 'otherIn' }, { field: 'totalIn' }],
  },
  {
    groupId: 'Out',
    description: 'Out',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'otherOut' }, { field: 'totalOut' }, { field: 'withdrawal' }],
  },
];

const TotalCreditPage = () => {
  const [date, setDate] = useState<DayjsTimestamp>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });

  const handleDateChange = (date: DayjsTimestamp) => {
    setDate(date);
  };

  const { userId } = useParams();

  const { data: balanceData, isLoading } = useQuery<PaginatedData<WalletCredit>>([QUERY_KEYS.credits, userId, date], {
    queryFn: () =>
      getData(
        'wallets/total-credits',
        {
          userId,
          fromTimestamp: date.fromTimestamp.valueOf(),
          toTimestamp: date.toTimestamp.valueOf(),
        },
        'wallet'
      ),
    enabled: !!userId,
  });

  return (
    <Box sx={styles.container}>
      <TableTemplate
        rows={balanceData?.items || []}
        columns={columns}
        rowCount={balanceData?.count || 0}
        loading={isLoading}
        getRowId={(row: WalletCredit) => row.dataType}
        sx={styles.table}
        showFilter={false}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        customTableHeaderContent={<DateRangeSelect date={date} onDateChange={handleDateChange} />}
      />
    </Box>
  );
};

export default TotalCreditPage;

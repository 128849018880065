import React from 'react';
import { SPORTSBOOK_FROM_OPTIONS } from '../../../constants';
import { DialogType } from '../../../@types';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import FormSelect from '../../molecules/FormSelect';
import useFilterForm from '../../../hooks/useFilterForm';
import CustomerSelect from '../../molecules/CustomerSelect';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';

const defaultValues = {
  playerIds: [],
  from: undefined,
};

type FilterBonusReportsData = {
  playerIds?: string[];
  from?: string;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
  closeModal: () => void;
};

const FilterBonusReports: React.FC<Props> = ({ changeQuery, closeModal }) => {
  const { control, handleSubmit, handleResetFilter } = useFilterForm<FilterBonusReportsData>({
    defaultData: defaultValues,
    changeQuery,
  });

  return (
    <FilterDialogLayout onSave={handleSubmit} onClose={closeModal} onReset={handleResetFilter}>
      <CustomerSelect
        name='playerIds'
        control={control}
        multipleSelect={false}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.userId === value);
          return option?.username
            ? `${option.firstName} ${option.lastName} (${option.username})`
            : 'No player selected';
        }}
      />
      <FormSelect name='from' control={control} label='From' options={SPORTSBOOK_FROM_OPTIONS} />
    </FilterDialogLayout>
  );
};

export default FilterBonusReports;

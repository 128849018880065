import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAuth } from '@neotech-solutions-org/neofusion-fe-shared';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, MenuList } from '@mui/material';
import { StyleObj } from '../../@types';

const styles: StyleObj = {
  accountButton: {
    p: 0,
    m: 0,
  },
  container: {
    minWidth: 120,
  },
  menuList: {
    '& .MuiList-root': {
      p: 0,
    },
    '& .MuiMenuItem-root': {
      px: 1.25,
      fontWeight: 600,
      ':hover': {
        backgroundColor: 'background.lighter',
      },
    },
  },
};

const AccountIcon: React.FC = () => {
  const [openAccountMenu, setOpenAccountMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { handleLogout } = useAuth();
  const navigate = useNavigate();
  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenAccountMenu(true);
  };
  const handleAccountMenuClose = () => {
    setAnchorEl(null);
    setOpenAccountMenu(false);
  };

  const handleClearSession = () => {
    sessionStorage.clear();
  };

  const handleLogoutClick = () => {
    handleLogout();
    handleAccountMenuClose();
    handleClearSession();
  };

  return (
    <>
      <IconButton
        size='large'
        aria-controls={openAccountMenu ? 'fade-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={openAccountMenu ? 'true' : undefined}
        edge='end'
        color='inherit'
        aria-label='menu'
        onClick={handleAccountMenuOpen}
        sx={styles.iconButton}
      >
        <AccountCircle color='primary' />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={openAccountMenu}
        onClose={handleAccountMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: -10, horizontal: 'center' }}
        elevation={3}
        MenuListProps={{
          disablePadding: true,
          sx: styles.menuList,
        }}
        slotProps={{
          paper: {
            sx: styles.container,
          },
        }}
      >
        <MenuList>
          <MenuItem onClick={() => navigate('/my-wallet')}>Wallet</MenuItem>
          <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default AccountIcon;
